import { useStorage } from '@vueuse/core'
import { useAccountStore } from '~/stores/account'
import { MSECONDS_IN_ONE_SECOND } from '~/consts'

export const DAY_COUNT_OF_OUTDATED_ONBOARDING = 14

export const TASK_TAG_ONBOARDING = 'onboarding'
export const TASK_COUNT_TO_COMPLETE_ONBOARDING = 3

const isOnboardingMarkedAsCompleted = useStorage(
  'is-onboarding-completed',
  false
)

export const useOnboarding = () => {
  const isOnboardingOutdated = useState(() => false)

  const { $time } = useNuxtApp()

  const accountStore = useAccountStore()
  const { account } = storeToRefs(accountStore)

  const checkIfOnboardingIsOutdated = () => {
    if (account.value) {
      const { past } = $time(account.value.created_at * MSECONDS_IN_ONE_SECOND)

      if (past) {
        isOnboardingOutdated.value =
          past.days.value >= DAY_COUNT_OF_OUTDATED_ONBOARDING
      }
    }

    return isOnboardingOutdated.value
  }

  const completeOnboarding = () => {
    isOnboardingMarkedAsCompleted.value = true
  }

  const isOnboardingInactive = computed(
    () => isOnboardingOutdated.value || isOnboardingMarkedAsCompleted.value
  )

  return {
    isOnboardingOutdated: readonly(isOnboardingOutdated),
    checkIfOnboardingIsOutdated,
    isOnboardingMarkedAsCompleted: readonly(isOnboardingMarkedAsCompleted),
    completeOnboarding,
    isOnboardingInactive
  }
}
