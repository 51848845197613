<template>
  <div :class="$style.container">
    <div :class="$style.group">
      <ui-select
        v-if="$matchMedia.down('md')"
        v-model="type"
        :class="$style.item"
        :options="menu"
        @update:model-value="onSelectMobMenuItem"
      />

      <ui-select
        v-model="status"
        :class="$style.item"
        :options="statusOptions"
        :default="defaultStatusOption"
        @update:model-value="onChangeStatus"
        reset-button
      />
    </div>

    <div :class="[$style.group, $style.filters]" v-if="$matchMedia.up('md')">
      <ui-menu :items="menu" @click="onClickMenuItem" />
    </div>
  </div>
</template>

<i18n>
{
  "ru": {
    "All tasks": "Все задания",
    "Intro tasks": "Вступительные задания",
    "Media & Codes": "Media & Codes",
    "Partners": "Партнерские",
    "PARI Cyber": "PARI Кибер",
    "PARI Sport": "PARI Спорт",
    "Others": "Другие",

    "Status": "Статус",
    "Available": "Доступные",
    "In progress": "В процессе",
    "Completed": "Выполненные"
  }
}
</i18n>

<script>
import UiSelect from '~/components/ui/select'
import UiMenu from '~/components/ui/menu'
import arrowDown from '~/assets/images/sort/arrow-down.svg?url'
import { PrivilegeKey, useAccountStore } from '~/stores/account'
import { useTasksListStore } from '~/stores/tasks/list'

export default {
  name: 'TaskFilters',
  components: {
    UiMenu,
    UiSelect
  },
  data() {
    return {
      type: '',
      sort: '',
      status: ''
    }
  },
  computed: {
    stat() {
      return this.$auth.account ? this.$auth.account.stat : null
    },
    statusOptions() {
      return [
        { title: this.t('Available'), id: 'available' },
        { title: this.t('In progress'), id: 'inprogress' },
        { title: this.t('Completed'), id: 'completed' }
      ]
    },
    defaultStatusOption() {
      return {
        id: null,
        title: this.t('Status'),
        icon: arrowDown
      }
    },
    menu() {
      return [
        { title: this.t('All tasks'), id: null, icon: arrowDown },
        {
          title: this.accountStore.hasPrivilege(PrivilegeKey.SPORT)
            ? this.t('PARI Cyber')
            : 'PARI',
          id: 'pari',
          icon: arrowDown
        },
        { title: 'CS2', id: 'cs', icon: arrowDown },
        { title: 'Dota 2', id: 'dota2', icon: arrowDown },
        { title: 'Социальные сети', id: 'socials', icon: arrowDown },
        { title: 'Коды', id: 'codes', icon: arrowDown },
        ...(this.accountStore.hasPrivilege(PrivilegeKey.VIP)
          ? [{ title: 'VIP', id: 'vip', icon: arrowDown }]
          : []),
        ...(this.accountStore.hasPrivilege(PrivilegeKey.SPORT)
          ? [{ title: this.t('PARI Sport'), id: 'sport', icon: arrowDown }]
          : []),
        { title: this.t('Others'), id: 'other', icon: arrowDown }
      ].map(item => ({
        ...item,
        active: item.id === this.type
      }))
    }
  },
  methods: {
    onChangeStatus(status) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          status
        }
      })
      this.$analytics.amplitude('filter_click', {
        filter_name: 'status',
        filter_value: status
      })
    },
    updateParams() {
      this.type = this.$route.query?.type || null
      this.status = this.$route.query?.status || this.defaults.status.default
    },
    onSelectMobMenuItem(type) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          type
        }
      })
      this.$analytics.amplitude('filter_click', {
        filter_name: 'type',
        filter_value: type
      })
    },
    onClickMenuItem(item) {
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          type: item.id
        }
      })
      this.$analytics.amplitude('filter_click', {
        filter_name: 'type',
        filter_value: item.id
      })
    }
  },
  mounted() {
    this.updateParams()
  },
  setup() {
    const { t } = useI18n()
    const accountStore = useAccountStore()
    const tasksListStore = useTasksListStore()

    return {
      t,
      accountStore,
      defaults: tasksListStore.defaults
    }
  },
  watch: {
    $route() {
      this.updateParams()
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 0 3.3em 1.8em 2.8em;
  border-bottom: 1px solid var(--t-app-div-color);
  @include down(md) {
    padding: 0;
  }
}
.group {
  display: flex;
  align-items: center;

  @include down(md) {
    @include hide-scrollbar;
    padding: 1.6em 4em;
    flex: 1 1;
    width: calc(100vw - 9em);
    justify-content: space-between;
    overflow: auto;
    border-top: 1px solid var(--t-app-div-color);
  }
  @include down(sm) {
    width: 100vw;
    padding: 1.6em;
  }
}
.group:nth-child(1) {
  margin-left: 1.5em;
  @include down(md) {
    margin-left: 0;
  }
}
.item + .item {
  margin-left: 1.5em;
}
</style>
