<template>
  <article :class="$style.card" :style="taskStyle">
    <div :class="$style.picture" v-if="0">
      <img :src="''" loading="lazy" decoding="async" alt="" />
    </div>

    <div :class="$style.head">
      <div :class="$style.reward">
        <ui-coin :value="1000" size="medium" />
      </div>
    </div>

    <div :class="$style.description"></div>

    <div :class="$style.progress"></div>

    <div :class="$style.details">
      <task-status status="new" :class="$style.status" />
      <div :class="$style.icons">
        <img :class="$style.icon" :src="IconCode" alt="" />
      </div>
    </div>
    <div :class="$style.lock">
      <div :class="$style.lockContainer">
        <template v-if="isOnboardingInactive || true"
          ><!-- // todo rm true, чтобы разблочить онбординг -->
          <div :class="$style.lockIcon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 330 330"
              xml:space="preserve"
            >
              <path
                fill="currentColor"
                d="M265,130h-15V84.999C250,38.13,211.869,0,165,0S80,38.13,80,84.999V130H65c-8.284,0-15,6.716-15,15v170 c0,8.284,6.716,15,15,15h200c8.284,0,15-6.716,15-15V145C280,136.716,273.284,130,265,130z M110,84.999 C110,54.673,134.673,30,165,30s55,24.673,55,54.999V130H110V84.999z M250,300H80V160h15h140h15V300z"
              />
              <path
                fill="currentColor"
                d="M196.856,198.144c-5.857-5.858-15.355-5.858-21.213,0L165,208.787l-10.644-10.643 c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213L143.787,230l-10.643,10.644 c-5.858,5.858-5.858,15.355,0,21.213c2.929,2.929,6.768,4.394,10.606,4.394s7.678-1.464,10.606-4.394L165,251.213l10.644,10.644 c2.929,2.929,6.768,4.394,10.606,4.394s7.678-1.464,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L186.213,230l10.643-10.644 C202.715,213.499,202.715,204.001,196.856,198.144z"
              />
            </svg>
          </div>
          <div :class="$style.lockMessage">
            <span :class="$style.text">
              {{ t('message') }}
            </span>
          </div>
        </template>
        <ui-button @click="skip" theme="accent" block size="large" fill v-else>
          {{ t('skip_onboarding') }}
        </ui-button>
      </div>
    </div>
  </article>
</template>

<i18n>
{
  "en": {
    "message": "You'll receive new tasks every day",
    "skip_onboarding": "Skip guide",
    "dialog-title": "Are you sure?"
  },
  "ru": {
    "message": "Новые задания появляются каждый день, не пропусти",
    "skip_onboarding": "Пропустить обучение",
    "dialog-title": "Вы уверены?"
  }
}
</i18n>

<script setup>
import IconCode from '~/assets/images/task/type/code.svg?url'
import UiCoin from '~/components/ui/coin'
import TaskStatus from '~/components/task/common/status'
import UiButton from '~/components/ui/button'

const { completeOnboarding, isOnboardingInactive } = useOnboarding()
const { t } = useI18n()
const { $dialog } = useNuxtApp()

const emit = defineEmits(['skip-onboarding'])

const taskStyle = {
  '--p': `0%`,
  '--style-bg': 'var(--t-app-l2-bg)',
  '--style-text': '#ffffff',
  '--style-progress-bg': '#36394C',
  '--style-progress-fill': 'linear-gradient(96.29deg, #FFE34F, #27FF88)'
}

const skip = () => {
  $dialog({
    wrapper: defineAsyncComponent(
      () => import('~/components/common/dialog/confirm-wrapper.vue')
    ),
    content: defineAsyncComponent(
      () => import('@/components/clans/members/modals/confirm-content.vue')
    ),
    contentProps: { title: t('dialog-title') },
    onClose: yes => {
      if (yes) {
        completeOnboarding()
        emit('skip-onboarding')
      }
    }
  })
}
</script>

<style lang="scss" module>
.card {
  position: relative;
  background: var(--style-bg);
  color: var(--style-text);
  padding: 2em;
  border-radius: 1em;

  @include down(sm) {
    border-radius: 1.2em;
  }

  &.skip {
    cursor: pointer;
  }
}
.picture {
  position: relative;
  padding-bottom: 46%;
  border-radius: 0.5em;
  overflow: hidden;
  margin-bottom: 2em;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.4em;
}
.reward {
  color: inherit;
}
.description {
  min-height: 14em;
}
.timerWrap {
  position: relative;
}
.progress {
  background: var(--style-progress-bg);
  height: 0.4em;
  margin-top: 2.6em;
  &:after {
    content: '';
    display: block;
    height: 100%;
    width: var(--p);
    background: var(--style-progress-fill);
  }
}
.details {
  margin-top: 3em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .icon {
    width: 2.2em;
    height: 2.2em;
    margin-left: 1.5em;
    color: rgba(255, 255, 255, 0.5);
  }
}
.status {
  flex: 1 1;
}
.lock {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
}
.lockContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3em;
}
.lockIcon {
  margin-bottom: 2em;
  color: #00c7b1;
  svg {
    width: 4em;
    height: 4em;
  }
}
.lockMessage {
  text-align: center;
  .text {
    font-weight: 400;
    font-size: 1.6em;
    line-height: 146%;
  }
}
</style>
