<template>
  <div>
    <div :class="$style.columns" v-if="ready">
      <div :class="$style.column" v-for="(column, i) in columns" :key="i">
        <div
          v-for="item in column"
          :key="item.id"
          :class="$style.item"
          :data-index="item.index"
        >
          <task-list-item
            v-if="item.type === 'task'"
            :source="item.source"
            @open="onOpenPopup"
            v-analytics.click.gtm="['paripass', 'tasks', 'task_click']"
          />
          <task-list-item-locked
            v-else-if="item.type === 'locked'"
            @skip-onboarding="$emit('skip-onboarding')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskListItem from '~/components/task/list/item'
import TaskListItemLocked from '~/components/task/list/locked'
import { getTaskStatus } from '~/components/task/helpers/helpers'

export default {
  name: 'TaskList',
  emits: ['open', 'skip-onboarding'],
  data() {
    return {
      ready: false,
      columnCount: 4
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    showLock: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TaskListItemLocked,
    TaskListItem
  },
  computed: {
    columns() {
      const items = this.items.map(item => ({
        type: 'task',
        source: item,
        id: item.id
      }))
      // if (items.length > 19) {
      //   items.splice(19, 0, { type: 'banner', id: 'banner1' })
      // }
      //
      // if (items.length > 14) {
      //   items.splice(14, 0, { type: 'banner', id: 'banner2' })
      // }
      //
      // if (items.length > 9) {
      //   items.splice(9, 0, { type: 'banner', id: 'banner3' })
      // }
      //
      // if (items.length > 4) {
      //   items.splice(4, 0, { type: 'banner', id: 'banner4' })
      // }

      if (this.showLock && this.$matchMedia.up('sm')) {
        items.splice(0, 0, { type: 'locked', id: 'locked' })
      }

      return items.reduce(
        (columns, item, i) => {
          item.index = i
          columns[i % this.columnCount].push(item)
          return columns
        },
        Array.from({ length: this.columnCount }, () => [])
      )
    }
  },
  methods: {
    getTaskRewardsForAmplitude(rewards) {
      return rewards.reduce((acc, el) => {
        acc[`task_reward_${el.resource.id}`] = el.amount
        return acc
      }, {})
    },
    getTaskStatus,
    calcColumnCount() {
      const width = window.innerWidth
      if (width < 569) {
        this.columnCount = 1
      } else if (width <= 784) {
        this.columnCount = 2
      } else if (width <= 1200) {
        this.columnCount = 3
      } else {
        this.columnCount = 4
      }
    },
    onOpenPopup(task) {
      this.$emit('open', task.id)
    },
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        entry.target.classList.add(this.$style.visible)
      } else {
        entry.target.classList.remove(this.$style.visible)
      }
    }
  },
  mounted() {
    this.calcColumnCount()
    this.ready = true
    window.addEventListener('resize', this.calcColumnCount)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calcColumnCount)
  }
}
</script>

<style lang="scss" module>
.columns {
  display: flex;
}
.column {
  flex: 1 1;
  min-width: 0;
}
.column + .column {
  margin-left: 2.4em;
}
.item {
  //opacity: 0;
  //transform: translate(0, 20%) scale(0.7);
  //transition: 0.3s transform ease, 0.45s opacity ease;
}
.visible {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}
.item + .item {
  margin-top: 2.3em;
  @include down(sm) {
    margin-top: 2.6em;
  }
}
</style>
